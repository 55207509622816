import PasswordCheck from './components/PasswordCheckComponent'
import ImageApprovalComponent from './components/ImageApprovalComponent'
import React from 'react'
import { useState } from 'react'

function App () {
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [csrfToken, setCsrfToken] = useState('')
  return (
    <div>
      {isLoggedIn ? (
        <ImageApprovalComponent getCsrfToken={() => csrfToken} />
      ) : (
        <PasswordCheck
          setLoggedInCallback={setLoggedIn}
          setCsrfTokenCallback={setCsrfToken}
          getCsrfToken={() => csrfToken}
          isLoggedIn={isLoggedIn}
        />
      )}
    </div>
  )
}

export default App
