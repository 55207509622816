import React, { useState, useEffect } from 'react'

type Props = {
  setLoggedInCallback: (value: boolean) => void
  setCsrfTokenCallback: (value: string) => void
  getCsrfToken: () => string
  isLoggedIn: boolean
}

export default function PasswordCheck({
  setLoggedInCallback,
  setCsrfTokenCallback,
  getCsrfToken,
  isLoggedIn
}: Props) {
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [gotCsrfToken, setGotCsrfToken] = useState(false)

  useEffect(() => {
    // Fetch the CSRF token from the server
    fetch(process.env.API_URL + '/csrf-token', {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        setCsrfTokenCallback(data.csrfToken)
        setGotCsrfToken(true)
      })
      .catch(error => console.error('Error fetching CSRF token:', error))
  }, [])

  useEffect(() => {
    if (getCsrfToken()) {
      validateJWT()
    }
  }, [gotCsrfToken])

  const validateJWT = () => {
    fetch(process.env.API_URL + '/validate-token', {
      method: 'GET',
      headers: {
        'x-csrf-token': getCsrfToken()
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setLoggedInCallback(true)
        } else {
          setLoggedInCallback(false)
        }
      })
      .catch(error => console.error('Error validating token:', error))
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      // Generate JWT token
      const response = await fetch(process.env.API_URL + '/login', {
        method: 'POST',
        headers: {
          'x-csrf-token': getCsrfToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password }),
        credentials: 'include'
      })

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`)
      }
      const data = await response.json()
      if (data.success) {
        setLoggedInCallback(true)
        setMessage('Correct password! Logging in...')
      } else {
        throw new Error('Authentication failed')
      }
    } catch (err) {
      console.error('Error:', err)
      setLoggedInCallback(false)
      setMessage('Incorrect password or server error. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoggedIn) {
    return <div></div>
  } else
    return (
      <div className='max-w-md mx-auto mt-10 bg-[#101010] p-6 rounded-lg shadow-lg shadow-[#101010] border-2 border-[#101010]'>
        <h1 className='text-2xl font-bold mb-4 text-[#fdfdfd]'>Moderation Login</h1>
        <p className='text-[#fdfdfd] mb-4'>Password</p>
        <form onSubmit={handleSubmit} className='space-y-4'>
          <input
            type='text'
            name='email'
            value='...'
            autoComplete='username email'
            style={{ display: 'none' }}
            readOnly
          />
          <input
            type='password'
            value={password}
            autoComplete='current-password'
            onChange={e => setPassword(e.target.value)}
            placeholder='Enter password'
            className='w-full px-3 py-2 text-sm border border-[#222222] rounded-md focus:outline-none focus:ring-2 focus:ring-[#222222] bg-[#101010] text-[#fdfdfd]'
          />

          <button
            type='submit'
            className='w-full px-4 py-2 bg-[#222222] text-[#fdfdfd] rounded-md text-sm font-medium hover:bg-[#333333] focus:outline-none focus:ring-2 focus:ring-[#222222] focus:ring-opacity-50 transition'
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {message && <div className='mt-4 text-[#fdfdfd]'>{message}</div>}
      </div>
    )
}